import * as server from "./server";
import * as browser from "./browser";
import {_wpnGetCurrentExperiments} from "./experiments";
import * as ui from "./ui";
import {_wpnRemoveUtmsFromUrl} from "./chat";
import {_wpnTranslate, _wpnFormatNumberAsCurrency} from "./locale";

/**
 * Perform page tagging based on the fact that we're visualizing products or thank-you pages
 */

export const _WPN_WEB_PAGE_TYPE_PRODUCT = 1;
export const _WPN_WEB_PAGE_TYPE_THANK_YOU = 2;

const _WPN_ELAPSED_SECONDS = "seconds";
const _WPN_ELAPSED_MINUTES = "minutes";
const _WPN_ELAPSED_HOURS = "hours";
const _WPN_ELAPSED_DAYS = "days";

const CONVERSION_GOAL = 16;
const CONVERSION_TIMEOUT = 604800; // 7 days
const CONVERSION_SENT_FLAG = "wpncvset";

//The width of each recommended product, to make them appear all in one line
const RECOMMENDED_PRODUCT_WIDTH = 190;
//The width considered as `desktop` for recommended items css
const RECOMMENDED_WIDTH_CSS_CHANGE = 700;

//We ignore elements whose father and grandfather (level 2 up from the candidate) are LI for product schema detection
const MAX_LEVEL_TO_IGNORE_LI_IN_SCHEMA_DETECTION = 2;

//The starting point in pixels of the bottom absolute position of the bubble
export const BUBBLE_BOTTOM_START = 15;

//The height and separation of the bubble in pixels (used in the chat bubble and the most viewed/history)
export const BUBBLE_HEIGHT_AND_SEPARATION = 58;

//Mshops carrousel delay to avoid the garbage collector. Mshops will work on an event to prevent this. No ETA defined.
const MSHOPS_CARROUSEL_DELAY_MS = 2500;


//Store provider constants
export const STORE_PROVIDER_TN = 3;
const STORE_PROVIDER_JUMPSELLER = 4;
const STORE_PROVIDER_SHOPIFY = 5;
const STORE_PROVIDER_TRAY = 6;
const STORE_PROVIDER_VTEX = 7;
const STORE_PROVIDER_MERCADOSHOPS = 10;
const STORE_PROVIDER_CUSTOM = 1000;
const STORE_PROVIDER_MAGENTO = 1001;
const STORE_PROVIDER_PRESTASHOP = 1002;
const STORE_PROVIDER_WOOCOMMERCE = 1003;

let _pageType = null;

//Tries to fetch the canonical url from the rel tag if it exists
export function _wpnGetCanonicalUrl() {
    const canonical = document.querySelector("link[rel='canonical']");
    if (canonical !== null)
        return canonical.href;
    return null;
}

//Tries to identify the page based on if it is a product page, a thank you page or null if it cannot identify the type
export function _wpnGetPageType(idClient = null) {
    if (_pageType === _WPN_WEB_PAGE_TYPE_PRODUCT || _pageType === _WPN_WEB_PAGE_TYPE_THANK_YOU) {
        return _pageType;
    }

    if (_wpnIsProduct()) {
        _pageType = _WPN_WEB_PAGE_TYPE_PRODUCT;
        return _WPN_WEB_PAGE_TYPE_PRODUCT;
    }

    if (_wpnIsThankYou()) {
        //Set a cookie to avoid duplication of hits. Checks that the URL previous stored URL is different from an already sent conversion hit
        let currentUrl = window.btoa(unescape(encodeURIComponent(window.location.href)));
        const cookieName = CONVERSION_SENT_FLAG+"-"+currentUrl;
        let previousConversion = browser._wpnGetCookie(cookieName);
        if ((previousConversion === null || previousConversion !== currentUrl) && idClient !== null) {
            browser._wpnSetCookieInSeconds(cookieName, currentUrl, CONVERSION_TIMEOUT);
            let order = trackOrderAndConversion(idClient);
            if (idClient == 22238 || idClient == 18039 || idClient == 40) {
                console.log("!order data: ", order);
            }
            let orderId = null;
            if (order !== null && order.hasOwnProperty('orderId')) {
                orderId = order.orderId;
            }
            server.trackEvent(idClient, CONVERSION_GOAL, 'goal', null, window.location.href, null, _WPN_WEB_PAGE_TYPE_THANK_YOU, null,
                null, null, null, orderId);
        }

        _pageType = _WPN_WEB_PAGE_TYPE_THANK_YOU;
        return _WPN_WEB_PAGE_TYPE_THANK_YOU;
    }

    return null;
}

//Models an order that is the result of data fetched from a Thank-you page and a conversion
class Order {
    constructor(id, idClient, amount, currency) {
        this.orderId = id;
        this.idClient = idClient;
        this.amount = amount;
        this.currency = currency;
        this.date = null;
        this.orderData = null; //raw data
    }
}

//Parse order details for TN
//@see: https://github.com/TiendaNube/api-docs/blob/master/resources/script.md#thank-you-page
function getOrderDataFromTiendanube(idClient) {
    let order = new Order(LS.order.id, idClient, LS.order.total/100, LS.currency); //TN Stores order amount in cents when in the checkout
    order.date = LS.order.created_at;
    order.orderData = JSON.stringify(LS.order); //add TN order complete as raw data to our Order object
    return order;
}

//Tracks the order and the conversion by sending data to a specified endpoint with the order data
function trackOrderAndConversion(idClient) {
    let order = null;
    if ((window['wpnObject'].hasOwnProperty('storeProvider') && window['wpnObject'].storeProvider === STORE_PROVIDER_TN) || _wpnCheckIsThankyouTiendaNube()) {
        order = getOrderDataFromTiendanube(idClient);
    }
    //Other store providers should be added later

    if (order != null && Object.keys(order).length > 0) {
        let postParams = new URLSearchParams(Object.keys(order).map(key => [key, order[key]]));
        server.sendToWPN('/track/order', "&"+postParams.toString());
    }

    return order;
}

function _wpnIsThankYou() {
    return _wpnCheckIsThankyouByUrl() || _wpnCheckIsThankYouByVariableForce() || _wpnCheckIsThankyouTiendaNube();
}

function _wpnCheckIsThankyouTiendaNube() {
    return typeof LS === 'object' && typeof LS.order === 'object' && typeof LS.order.id === "number";
}

function _wpnIsTiendanubeProduct() {
    return typeof LS === 'object' && typeof LS.product === 'object' && typeof LS.product.id === "number";
}

function _wpnCheckIsThankyouByUrl() {
    return window.location.href.indexOf("orderPlaced") > -1 || window.location.href.indexOf("checkout/success") > -1 || _wpnIsEmpretiendaCheckOut(window.location.href);
}

/**
 * Check if the url passed is adequate for an Empretienda successful checkout URL
 * @param urlString
 * @returns {boolean}
 * @private
 */
function _wpnIsEmpretiendaCheckOut(urlString) {
    try {
        const url = new URL(urlString);
        const containsOrder = url.pathname.includes("order");

        const params = new URLSearchParams(url.search);
        const containsOrderStatus = params.get("order_status") === "success";
        return containsOrder && containsOrderStatus;
    } catch (error) {
        return false;
    }
}

function _wpnCheckIsThankYouByVariableForce() {
    return window._wpnPageTypeForce === _WPN_WEB_PAGE_TYPE_THANK_YOU;
}

function _wpnCheckIsBlockedMshops() {
    if (typeof meli_ga === "function") {
        if (typeof meli_ga.getAll === "function") {
            try {
                let tracker = meli_ga.getAll()[0];
                let dimensionValue = tracker.model.data.ea[':dimension39'];
                if (dimensionValue === 'paused') {
                    return true;
                }
            } catch (e) {
            }
        }
        if (document.querySelector('.ui-pdp-no-stock-shops') !== null) {
            return true;
        }
    }
    return false;
}

function _wpnIsProduct() {
    let byOg = _wpnCheckIsProductByOgType();
    let byVtex = parsedProduct !== null || _wpnCheckIsProductVtex();
    let bySchema = parsedProduct !== null || _wpnCheckIsProductBySchema();
    let byFacebook = parsedProduct !== null || _wpnCheckIsProductTypeByFacebook();

    let isMshopsBlocked = _wpnCheckIsBlockedMshops();

    return !isMshopsBlocked && (byOg || byVtex || bySchema || byFacebook);
}

function _wpnCheckIsProductByOgType() {
    const priceMetatags = ["og:price:amount", "og:price", "product:price:amount", "tiendanube:price", "nuvemshop:price"];
    const nodes = document.querySelectorAll('[property="og:type"]');
    //Check for type product or nuvemshop_product but not product.category or similar
    if (nodes.length > 0 && nodes[0].content.match(/product/i) != null && nodes[0].content.match(/category/i) == null && nodes[0].content.match(/group/i) == null) {
        if (parsedProduct === null) {
            //Product not detected, try to fetch the product details by parsing the og tags
            let url = window.location.href;
            let name, image = "";
            let price = 0.0;
            let currency = null;

            //OG or TN meta tags
            const metatags = document.querySelectorAll("meta[property^=og], meta[property^=tiendanube], meta[property^=product], meta[property^=nuvemshop]");
            Array.prototype.forEach.call(metatags, function (meta) {
                const property = meta.getAttribute("property");
                const value = meta.getAttribute("content");

                if (property === "og:url" && value.length > 0) {
                    url = value;
                }

                if (property === "og:image:secure_url") {
                    image = value;
                }
                if (property === "og:image" && image.length == 0) { //To avoid overwriting in case it matches with secure url
                    image = value;
                }

                if (priceMetatags.includes(property) && value !== undefined && price === 0) {
                    let priceStr = value;
                    if (/^[0-9]{1,3}(\.[0-9]{3})*(,[0-9]+)?$/.test(priceStr)) {
                        priceStr = priceStr.replaceAll(".", "");
                        priceStr = priceStr.replaceAll(",", ".");
                    } else if (/^[0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)?$/.test(priceStr)) {
                        priceStr = priceStr.replaceAll(",", "");
                    }
                    if (parseFloat(priceStr) > 0) {
                        price = parseFloat(priceStr);
                    }
                }

                if (property === "og:title") {
                    name = value;
                }
            });

            //Check for currency meta tag information and add it to the product
            const currencyMetas = document.querySelectorAll("meta[property*='currency' i], meta[name*='currency' i], meta[itemprop*='currency' i]");
            Array.prototype.forEach.call(currencyMetas, function (meta) {
                //In the case of the currency, check if other attribute has the required information
                const property = meta.getAttribute("property");
                const value = meta.getAttribute("content");
                const itemprop = meta.getAttribute("itemprop");
                const metaName = meta.getAttribute("name");
                const priceCurrencyMetatags = ["priceCurrency", "og:price:currency", "currency", "product:price:currency"];
                if (priceCurrencyMetatags.includes(property) || priceCurrencyMetatags.includes(itemprop) || priceCurrencyMetatags.includes(metaName)) {
                    currency = value;
                }
            });

            return ensureProductInPage(name, price, url, image, currency);
        }
        return true;
    }
    return false;
}

function _wpnCheckIsProductVtex() {
    if ((typeof skuJson !== "undefined") && Boolean(skuJson)) {
        if (typeof skuJson == "object" && typeof skuJson.skus == "object" && typeof skuJson.skus[0] == "object" && skuJson.skus[0].available) {
            //Price is obtained the same as the parsers @see parser.go (parseVtex function)
            if (parsedProduct === null) {
                let price = parseFloat(skuJson.skus[0].bestPrice) / 100;
                let tax = parseFloat(skuJson.skus[0].taxAsInt);
                if (tax > 0) {
                    price += (tax / 100);
                }
                return ensureProductInPage(skuJson.skus[0].skuname, price, window.location.href, skuJson.skus[0].image);
            }
            return true;
        }
    }

    return false;
}

function _wpnCheckIsProductWooCommerce() {
    return (typeof wc_single_product_params !== "undefined");
}

function _wpnCheckIsProductTypeByFacebook() {
    let nodes = document.querySelectorAll('html');
    if (nodes.length > 0) {
        let reg = new RegExp("fbq\\('track'\\s*,\\s*'ViewContent'\\s*,\\s*(\\{.*?['\"]{0,1}content_type['\"]{0,1}\\s*:\\s*['\"]product['\"].*?\\})\\)", "igsm");
        return nodes[0].textContent.match(reg) != null;
    }
    return false;
}

function isProductNode(node) {
    if (typeof (node.tagName) === 'undefined') {
        return false;
    }
    const itemtypeProperty = node.getAttribute("itemtype");
    return typeof (itemtypeProperty) === "string" && itemtypeProperty.includes("schema.org/Product");
}

//Extracts the value from the node. It will try to get the textContent but might be empty if tag is "meta"
function getValueForSchemaFromNode(node) {
    let value = node.textContent;

    if (value === "" && node.tagName.toLowerCase() === "meta") {
        value = node.getAttribute('content');
    }

    return value;
}

//This function checks that only one product exist in the page in the same hierarchy.
//A product page can have multiple other products (recommendations for example), but should not be "on the same level"
//in the HTML that the currently product being analyzed.
function _wpnCheckIsProductBySchema() {
    let candidates = [];
    let productNodes = document.querySelectorAll('[itemtype*="schema.org/Product"]');
    if (productNodes.length > 0) {
        let _parentNodes = [];
        let i, j, element, _currentElementAncestors, currentElementValid, totalValid, currentFatherNumber;
        //Go over every product detected (as schema.org/Product to check if the page is a product page or,
        // a search result for example)
        for (i = 0; i < productNodes.length; i++) {
            let candidate = productNodes[i];

            //The element is a list element, we should not consider it
            if (candidate.tagName === "LI") {
                continue;
            }

            //If the markup explicitly says I'm a part of a list, then I'm not a candidate since it might be a product page or a checkout page
            if (candidate.getAttribute("itemprop") === "itemListElement") {
                continue;
            }

            element = productNodes[i];
            _currentElementAncestors = [];
            currentElementValid = true;
            currentFatherNumber = 0;
            //Take the element and get to the document (root) of the HTML to check hierarchy
            while (element.parentNode !== document) {
                for (j = 0; j < _parentNodes.length; j++) {
                    //If the current father matches another father detected before, then, the current element is part
                    //of the list and should not be considered as unique (should not be considered the page as product
                    //for the sake of this element), so we set it as false
                    if (typeof _parentNodes[j]["nodes"][currentFatherNumber] !== "undefined") {
                        if (_parentNodes[j]["nodes"][currentFatherNumber] === element.parentNode) {
                            _parentNodes[j]["valid"] = false;
                            currentElementValid = false;
                            break;
                        }
                    }
                }

                //If this element has been invalidated, then go check the next one
                if (!currentElementValid) {
                    break;
                }

                //If the original element's father or grandfather is a list element, we should not consider this element
                if (currentFatherNumber < MAX_LEVEL_TO_IGNORE_LI_IN_SCHEMA_DETECTION && element.parentNode.tagName === "LI") {
                    currentElementValid = false;
                    break;
                }

                //Try to get the item type of a parent node. If it matches the one of a list, ignore it since it may be a checkout page or a product listing
                let parentNodeItemType = element.parentNode.getAttribute("itemtype");
                if (parentNodeItemType !== null && parentNodeItemType.match(/schema\.org\/ItemList/i) !== null) {
                    break;
                }

                //The element is valid, so push the parent node and iterate recursively
                _currentElementAncestors.push(element.parentNode);
                element = element.parentNode;
                currentFatherNumber++;
            }

            if (currentElementValid) {
                //If this element is the only one present in the page, we should consider the page as product
                //Set the parent nodes of this valid element, to check and compare with other elements (previous for)
                candidates.push(candidate);
                _parentNodes.push({
                    valid: true,
                    nodes: _currentElementAncestors
                });
            }
        }

        totalValid = 0;
        //Just add the totalValid products, as if more than one product is valid, the page should not be considered a
        //product page, and would be a search result page instead (for example)
        for (j = 0; j < _parentNodes.length; j++) {
            if (_parentNodes[j]["valid"]) {
                totalValid++;
            }
        }

        //If I have a single product as a candidate, try to parse its contents
        let valid = false;
        if (totalValid === 1 && candidates.length === 1 && parsedProduct === null) {
            let name, price, url, image;

            let tr = function (node) {
                for (let i = 0; i < node.children.length; i++) {
                    let child = node.children[i];
                    if (child.hasAttribute("itemprop")) {
                        let attrValue = child.getAttribute("itemprop");

                        url = window.location.href;
                        if (attrValue === "image" && image === undefined) {
                            image = child.src;

                            if (image === undefined) {
                                image = getValueForSchemaFromNode(child);
                            }
                        }

                        if (attrValue === "price" && price === undefined) {
                            price = getValueForSchemaFromNode(child);
                        }

                        if (attrValue === "name" && name === undefined) {
                            name = getValueForSchemaFromNode(child);
                        }
                    }
                    //Recursively run, as itemprops could be part of the inner structure of the product HTML
                    tr(child);
                }
            }

            tr(candidates[0]); //Run through the first (and only) candidate and then recursively
            if (parsedProduct === null) {
                valid = ensureProductInPage(name, price, url, image);
            } else {
                valid = true;
            }
        }

        //If there is just one product and it is valid, we should consider the page as product by schema
        if (totalValid === 1 && valid) {
            return valid;
        }
    }

    //Schema could be inserted as application/ld+json and should be unique (that's why we check nodes[0] internally)
    let nodes = document.querySelectorAll('script[type="application/ld+json"]');
    if (nodes.length > 0) {
        try {
            let cleanJson = nodes[0].innerHTML.replace(/(\r\n|\n|\r)/gm, "");
            let data = JSON.parse(cleanJson);

            if (Array.isArray(data)) {
                // Look for products in the array
                for (let item of data) {
                    let res = parseJsonLd(item);
                    if (res) {
                        return res;
                    }
                }
            } else {
                return parseJsonLd(data);
            }
        } catch (error) {
            return false;
        }
    }
    return false;
}

//Look for prooducts in the ld+json object. In case of a main entity, parse it if it contains a product
function parseJsonLd(data) {
    let type = data['@type'] || "";

    // Check if we're dealing with webpage main entity such as TN
    if (type && type.match(/webpage/i)) {
        // Check if it exists and that it is a product
        if (data.mainEntity &&
            data.mainEntity['@type'] &&
            data.mainEntity['@type'].toString().match(/product/i)) {
            return parseJsonLdProductObject(data.mainEntity);
        }
    }

    if (type && type.match(/product/i)) {
        return parseJsonLdProductObject(data);
    }
}

//From a product object retrieved from a ld+json data, fetch the product details
function parseJsonLdProductObject(data) {
    let name, price, url, image;

    name = data.name || "";
    url = data.url || window.location.href;

    // look for images
    image = data.image || "";
    if (typeof image === "object") {
        if (Array.isArray(image) && image.length > 0) {
            image = image[0];
        } else {
            // Manejar caso de objeto de imagen
            image = image.url || image.image || "";
        }
    }

    // Look for the product's price
    if (data.offers && typeof data.offers === "object") {
        // Manejar caso de múltiples ofertas
        if (Array.isArray(data.offers)) {
            for (let offer of data.offers) {
                if (offer.price &&
                    (!offer.availability ||
                     offer.availability.toLowerCase().includes("instock"))) {
                    price = offer.price;
                    break;
                }
            }
        } else {
            // Case of a single offer
            price = data.offers.price || "";
        }
    }

    // Validate and store the product details accordingly
    return ensureProductInPage(name, price, url, image);
}

//The parsed product of the current page. This works also as a barrier that in case of parsing successfully a product, other product detections or parsing will stop
let parsedProduct = null;

//Clears the parsed product and the page type since the mutation observer might detect a page change and the new one might be different
export function clearParsedProductAndPageType() {
    parsedProduct = null;
    _pageType = null;
}

//Ensures that the product is valid and adds it as the current parsed product and to the product history
function ensureProductInPage(name, price, url, image, currency = null) {
    let prod = new Product(name, price, url, image, new Date());
    if (prod.isValid()) {
        if (currency != null) {
            prod.currency = currency;
        }
        parsedProduct = prod;
        addProductToHistory(prod);
        return true;
    }
    return false;
}

// Last visit + History + Most Viewed products + Recommended items event
const LAST_VISIT_EVENT = 13;
const HISTORY_EVENT = 14;
const MOST_VIEWED_EVENT = 15;
const RECOMMENDED_ITEMS_EVENT = 17;
const HISTORY_CARROUSEL_EVENT = 18;
const MOST_VIEWED_CARROUSEL_EVENT = 19;

const HISTORY_MAX_CAPACITY = 10;
const LOCAL_HISTORY_CACHE_KEY = "wpn-local-history";
const LOCAL_HISTORY_POLL_INTERVAL_MS = 250;
const LOCAL_HISTORY_LAST_UPDATE_KEY = 'wpn-local-history-ts';
const LOCAL_HISTORY_MILISECONDS_EXPIRE = 259200000; //72hours

const CONFIGURATION_CACHE_DURATION = 300;
const RECOMMENDED_PRODUCT_CACHE_DURATION = 600;

const LAST_VISIT_SECONDS_TO_DISPLAY = 3; //TODO: Agregar valor correcto (hoy en dia no se sabe cuanto hay que usar)  ¿Se sabe hoy (03/02/2022)?
const LAST_VISIT_USED_WIDGET = "_wpnlvusw";
const LAST_VISIT_COOLDOWN = 259200; //3 days
const LAST_VISIT_TIME_TO_SHOW = 3600;//1 hour


//Retrieves and optionally filters the products from local storage based on the maxAgeForProducts configuration parameter
function getLocalHistory(maxAgeForProducts = null) {
    let history = null;
    let saved = localStorage.getItem(LOCAL_HISTORY_CACHE_KEY);
    if (saved === null) {
        return [];
    } else {
        let retrieve = JSON.parse(saved);
        history = retrieve.map(function (p) {
            return Object.assign(new Product(), p);
        });

        if (maxAgeForProducts !== null && maxAgeForProducts > 0) {
            const previousLength = history.length;
            const currentTime = new Date();
            let filtered = history.filter(function(p) {
                let productSeenDate = p.seenAt;
                if (typeof productSeenDate === "string") {
                    productSeenDate = new Date(productSeenDate);
                }
                let elapsed = Math.abs((currentTime - productSeenDate))
                elapsed = Math.floor(elapsed / (24 * 60 * 60 * 1000));
                return elapsed < maxAgeForProducts;
            })

            if (history.length > filtered.length) {
                //Re-store the filtered products to local storage
                localStorage.setItem(LOCAL_HISTORY_CACHE_KEY, JSON.stringify(filtered));
            }
        }
    }

    return history;
}

//Marks the current time to be the last time local history has been checked
function markProductHistoryLastCheckTime() {
    localStorage.setItem(LOCAL_HISTORY_LAST_UPDATE_KEY, (new Date()).getTime().toString())
}

//Adds a product to the local productHistory array ensuring the maximum length by popping
//the last (older) elements when needed
function addProductToHistory(product) {
    let history = getLocalHistory();
    product.url = product.url.split("#")[0]; //Remove anchors
    history.unshift(product);
    for (let i = 1; i < history.length; i++) {
        if (history[i].url === product.url) {
            history.splice(i, 1);
        }
    }

    //First or only element, now it is the last update
    if (history.length === 1) {
        markProductHistoryLastCheckTime();
    }

    if (history.length > HISTORY_MAX_CAPACITY) {
        history.pop();
    }

    //Lets add the last visit widget cookie so that the widget won't appear just right now when in the same session.
    browser._wpnSetCookieInSeconds(LAST_VISIT_USED_WIDGET, 1, LAST_VISIT_TIME_TO_SHOW);
    localStorage.setItem(LOCAL_HISTORY_CACHE_KEY, JSON.stringify(history));
}

//Checks if configuration widget is cached, otherwise hits the server with the requested data
function getWidgetSettings(idClient, endpoint, configurationName, options = {cache: true}) {
    let cached = browser.getFromLocalStorageWithExpiration(configurationName);
    if (cached === null || typeof cached === 'undefined' || cached === "undefined") {
        //TODO: podría ser menos groncho que concatenar parámetros manualmente
        if (idClient != null) {
            return server.retrieveFromWPN(endpoint, "idClient=" + idClient).then(function (configuration) {
                if (options.cache) { //Store config in local storage cache
                    browser.storeInLocalStorageWithExpiration(configurationName, JSON.stringify(configuration), CONFIGURATION_CACHE_DURATION);
                }
                return configuration;
            });
        } else {
            cached = "{}"; //in case idClient not defined, populate
        }
    }

    return Promise.resolve(JSON.parse(cached));
}

//Returns whether the current location matches the homepage
function isHomePage(idClient) {
    //Just for client 47501 that has a different home page; if needed for more, we could add a configuration
    return window.location.pathname === "/" || (idClient === 47501 && window.location.pathname === "/app");
}

//Stores the fact that the last visit widget was used and do not show for LAST_VISIT_COOLDOWN
//Also check if that is in the homepage, otherwise ignore.
function shouldDisplayLastVisit(idClient) {
    let widgetIsUsed = browser._wpnGetCookie(LAST_VISIT_USED_WIDGET);
    return parseInt(widgetIsUsed, 10) !== 1 && isHomePage(idClient);
}

//Renders the last visit widget
export function renderLastVisit(idClient) {
    //Get the visual configuration if not cached already
    getWidgetSettings(idClient, "/product/last-visit", 'last-visit').then(function (config) {
        if (config !== undefined && config.templateMultipleProducts !== undefined) {
            let products = getLocalHistory(config.maxAgeForProducts);
            const upgradeConfig = getUpgradeOnSiteConfiguration(config);
            if (checkWidgetShouldBeDisplayed(upgradeConfig) && (products.length > 0 && shouldDisplayLastVisit(idClient))) {
                let lastVisitProducts = products.slice(0, 2); //Extract at most 2 products and use them
                let template = config.templateMultipleProducts;
                //Render widget after delay
                const widgetHtml = addProductsToLastVisitTemplate(template, lastVisitProducts, upgradeConfig);
                const upgradeShouldBeDisplayed =  upgradeConfig.enabled;
                addWidget(idClient, config, widgetHtml, "lv", LAST_VISIT_EVENT);
                if(upgradeShouldBeDisplayed && document.getElementById('store_bar_iframe')){ //Only for admins
                    const upgradeHtml = buildUpgradeOnsiteHtml(upgradeConfig, true);
                    const lastVisitContainer = document.querySelector(".wpn-container-lv-products");
                    if(lastVisitContainer) {
                        lastVisitContainer.style.position = "relative";
                        lastVisitContainer.insertAdjacentHTML('beforeend', upgradeHtml);
                    }
                }
                if (products.length === 1) {
                    let ignoredProducts = document.getElementsByClassName('lv-product-2');
                    for (let i = 0; i < ignoredProducts.length; i++) {
                        ignoredProducts[i].style.display = "none";
                    }
                }
                openModal("wpn-lv-container", idClient);
                browser._wpnSetCookieInSeconds(LAST_VISIT_USED_WIDGET, 1, LAST_VISIT_COOLDOWN);
                //Add event when opening the modal here because on the AddWidget function is binded to a link that is not needed here
                server.trackEvent(idClient, LAST_VISIT_EVENT, "open", null, window.location.href, null, _wpnGetPageType(), _wpnGetCanonicalUrl(),
                    _wpnRemoveUtmsFromUrl(document.referrer), _wpnGetCurrentExperiments(), null);
            }
        }
    });
}

//Just for code clarity and usage in conditionals
function isNumeric(num) {
    return !isNaN(num)
}

//Build the upgrade onsite
function buildUpgradeOnsiteHtml(configuration, appendDirectlyToProducts = false) {
    let fullContainerStyles = 'width: 100%;position: absolute;top: 50px;background: rgba(255,255,255,0.9);z-index: 999999;';
    let upgradeContainerStyles = ';height: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;text-align: center;'
    let textStyles = 'font-family: Roboto, Arial, sans-serif;font-size: 18px;font-weight: 500;line-height: 18px;letter-spacing: 0px;margin-bottom: 20px;';
    let buttonContainerStyles = 'display: flex;height: 44px;padding: 0px 24px;justify-content: center;align-items: center;gap: 8px;border-radius: 8px;background: #0077FF';
    let ctaStyles = 'color: white; text-decoration: none;font-family: Open Sans, Arial, sans-serif;font-size: 14px;font-style: normal;font-weight: 600;line-height: 20px;';
    if (appendDirectlyToProducts) {
        fullContainerStyles += 'height: 100%;top:0;';
        upgradeContainerStyles += 'width: 100%';
    } else {
        fullContainerStyles += 'height: calc(100% - 50px);top:50px;';
        upgradeContainerStyles += 'width: calc(100% - 30px)';
    }
    return '<div id="wpn-upgrade-onsite-container" style="' + fullContainerStyles + '">' +
          '<div class="wpn-full-upgrade-container" style="'+ upgradeContainerStyles + '">' +
            '<div class="wpn-upgrade-text" style="'+ textStyles +'">'+configuration.text+'</div>' +
            '<div class="wpn-upgrade-cta-container" style="'+buttonContainerStyles+'">' +
                '<a class="wpn-upgrade-cta" href="'+configuration.url+'" style="'+ ctaStyles+ '" target="_blank">' + configuration.cta + '</a>' +
            '</div>' +
          '</div>' +
      '</div>';
}

//Checks if a given URL matches the current domain and replaces it if different
function replaceDomainIfDifferent(url) {
    // Create an anchor element to easily parse the URL
    const link = document.createElement('a');
    link.href = url;

    // Get the current page's domain
    const currentDomain = window.location.hostname;

    if(typeof currentDomain === 'undefined' || currentDomain === null || currentDomain === '' || currentDomain === 'localhost'){
        return url;
    }

    // Check if the URL's domain matches the current domain
    if (link.hostname !== currentDomain) {
        // Replace the domain with the current domain
        return url.replace(link.hostname, currentDomain);
    }

    // Return the original URL if the domains match
    return url;
}

//Adds the product array to the html via replacing the corresponding fields in the provided productTemplate
function addProductsToTemplate(containerTemplate, productTemplate, products, upgradeOnsiteConfiguration = null) {
    let productHtml = "";
    let now = new Date();
    let index = 1;

    const upgradeShouldBeDisplayed =  upgradeOnsiteConfiguration !== null && upgradeOnsiteConfiguration.enabled
        && document.getElementById('store_bar_iframe'); //Only for admins;
    products.forEach(function (prod) {
        let timeElapsed = '';
        if (prod.seenAt) {
            timeElapsed = getElapsedTime(prod.seenAt, now)
        }

        let price = prod.price;
        let oldPrice = "";

        if (price !== undefined && isNumeric(price)) {
            price = _wpnFormatNumberAsCurrency(price, prod.currency);
        }

        if (prod.promotionalPrice !== undefined && prod.promotionalPrice !== null && isNumeric(prod.promotionalPrice)) {
            oldPrice = price;
            price = _wpnFormatNumberAsCurrency(prod.promotionalPrice, prod.currency);
        }

        const finalUrl = replaceDomainIfDifferent(prod.url);

        let productTemplateForProd = productTemplate.replace(/{{name}}/gi, prod.name)
            .replace(/{{price}}/gi, price)
            .replace(/{{oldPrice}}/gi, oldPrice)
            .replace(/{{url}}/gi, upgradeShouldBeDisplayed ? "#" : finalUrl)
            .replace(/{{image}}/gi, prod.image)
            .replace(/{{index}}/gi, index)
            .replace(/{{seenAt}}/gi, timeElapsed);
        if (!prod.seenAt) {
            //Remove all the span containing the date in this case
            let regex = new RegExp("<div class=\"wpn-(h|lv|mv)-single-product-seen-at\">.*<\/div>", "gim");
            productTemplateForProd = productTemplateForProd.replace(regex, "");
        }
        productHtml += productTemplateForProd
        index++
    })

    if (upgradeShouldBeDisplayed) {
        const upgradeHtml = buildUpgradeOnsiteHtml(upgradeOnsiteConfiguration);
        productHtml = upgradeHtml + productHtml;
    }

    return containerTemplate.replace(/{{products}}/gi, productHtml);
}

//Allows to remove a widget from the page, it will try to remove the bubble, the container and the inline container
export function removeWidget(widgetTypeIndicator) {
    const bubbleSelector = 'wpn-' + widgetTypeIndicator + '-bubble';
    const containerSelector = 'wpn-' + widgetTypeIndicator + '-container';
    const inlineSelector = 'wpn-' + widgetTypeIndicator + '-inline';

    const bubble = document.getElementById(bubbleSelector);
    const container = document.getElementById(containerSelector);
    const inline = document.getElementById(inlineSelector);

    //Remove bubble from the widget zone register and relocate the existing in order from bottom
    const rearrangeBubbles = function(bubbles, widget) {
        let index = bubbles.indexOf(widget);
        if (index > -1) {
            bubbles.splice(index, 1);
            bubbles.forEach(function(widget, i, a){
                const bubbleToModify = document.getElementById('wpn-' + widget + '-bubble');
                if (bubbleToModify) {
                    bubbleToModify.style.bottom = (BUBBLE_BOTTOM_START + BUBBLE_HEIGHT_AND_SEPARATION * (i)) + "px";
                }
            });
        }
    }

    if (bubble) {
        bubble.remove();
        if (window['wpnObject'].hasOwnProperty('bubbles')) {
            rearrangeBubbles(window['wpnObject'].bubbles.left, widgetTypeIndicator);
            rearrangeBubbles(window['wpnObject'].bubbles.center, widgetTypeIndicator);
            rearrangeBubbles(window['wpnObject'].bubbles.right, widgetTypeIndicator);
        }
    }
    if (container) {
        container.remove();
    }
    if (inline) {
        inline.remove();
    }
}

//Adds the product array to the html via replacing the corresponding fields in the provided productTemplate
function addProductsToLastVisitTemplate(template, products, upgradeOnsiteConfiguration = null) {
    let i = 1;
    let now = new Date();
    const upgradeShouldBeDisplayed =  upgradeOnsiteConfiguration !== null && upgradeOnsiteConfiguration.enabled &&
        document.getElementById('store_bar_iframe'); //Only for admins;
    products.forEach(function (prod) {
        let rname = new RegExp("{{products." + i + ".title}}", "gi")
        let rprice = new RegExp("{{products." + i + ".price}}", "gi")
        let rurl = new RegExp("{{products." + i + ".url}}", "gi")
        let rimage = new RegExp("{{products." + i + ".image}}", "gi")
        let rseenat = new RegExp("{{products." + i + ".seenAt}}", "gi")

        let timeElapsed = '';
        if (prod.seenAt) {
            timeElapsed = getElapsedTime(prod.seenAt, now)
        }
        if (prod.price !== undefined) {
            prod.price = _wpnFormatNumberAsCurrency(prod.price, prod.currency);
        }

        const finalUrl = replaceDomainIfDifferent(prod.url);

        template = template.replace(rname, prod.name)
            .replace(rprice, prod.price)
            .replace(rurl, upgradeShouldBeDisplayed ? "#" : finalUrl)
            .replace(rimage, prod.image)
            .replace(rseenat, timeElapsed);

        if (!prod.seenAt) {
            //Remove all the span containing the date in this case
            let regex = new RegExp("<div class=\"wpn-lv-single-product-seen-at product-" + i + "-seen-container\">.*<\/div>", "gim");
            template = template.replace(regex, "");
        }
        i++;
    })

    return template;
}

//Returns the elapsed time between to given Date objects
function getElapsedTime(prevTime, currentTIme) {
    let elapsedAmount = _WPN_ELAPSED_SECONDS;
    if (typeof prevTime === "string") {
        prevTime = new Date(prevTime);
    }
    let elapsed = Math.abs((currentTIme - prevTime) / 1000)

    //Calculate time elapsed in seconds, minutes, hours or days.
    if (elapsed > 60) {
        elapsedAmount = _WPN_ELAPSED_MINUTES;
        elapsed /= 60;
        elapsed = Math.floor(elapsed)
        if (elapsed > 60) {
            elapsedAmount = _WPN_ELAPSED_HOURS;
            elapsed /= 60;
            elapsed = Math.floor(elapsed)
            if (elapsed > 24) {
                elapsedAmount = _WPN_ELAPSED_DAYS;
                elapsed /= 24;
                elapsed = Math.floor(elapsed)
            }
        }
    }
    elapsed = Math.floor(elapsed);

    if (elapsed === 1) {
        elapsedAmount = elapsedAmount.substr(0, elapsedAmount.length - 1);
    }

    return elapsed + " " + _wpnTranslate(elapsedAmount);
}

//Checks if the upgrade onsite should be rendered
function getUpgradeOnSiteConfiguration(config)
{
    let configuration = { enabled: false };
    if(config.upgradeOnsite && config.upgradeText && config.upgradeUrl && config.upgradeCTA){
        configuration.enabled = true;
        configuration.text = config.upgradeText;
        configuration.url = config.upgradeUrl;
        configuration.cta = config.upgradeCTA;
    }
    return configuration;
}

//Renders the product productHistory
export function renderProductHistory(idClient) {
    //Get the visual configuration if not cached already
    getWidgetSettings(idClient, "/product/history", 'productHistory').then(function (config) {
        const widgetTypeIndicator = "h";
        if (config !== undefined && config.template !== undefined) {
            //Fetch the most viewed product
            let products = getLocalHistory(config.maxAgeForProducts);
            if (products.length > 0) {
                let widgetAvailableForURL = browser.appliesForCurrentURL(config.display, config.pages, window.location.href);
                const displayInlineAndIsProductOrHome = config.showInline && (isHomePage(idClient) || _wpnGetPageType() ===_WPN_WEB_PAGE_TYPE_PRODUCT);

                const upgradeConfig = getUpgradeOnSiteConfiguration(config);
                if (checkWidgetShouldBeDisplayed(upgradeConfig) && (widgetAvailableForURL || displayInlineAndIsProductOrHome)) {
                    const widgetHtml = addProductsToTemplate(config.template, config.productTemplate, products, upgradeConfig);
                    addWidget(idClient, config, widgetHtml, widgetTypeIndicator, HISTORY_EVENT,  "open", widgetAvailableForURL);

                    if (displayInlineAndIsProductOrHome) {
                        let productsToDisplay = 7;
                        if (browser._wpnGetDeviceType() === browser.DEVICE_PHONE) {
                            productsToDisplay = 4;
                        }
                        products = products.slice(0, productsToDisplay);
                        const inlineHtml = addProductsToTemplate(config.inlineTemplate, config.inlineProductTemplate, products);

                        function inject() {
                            if ('delayInline' in window.wpnObject && window.wpnObject.delayInline) {
                                setTimeout(function() {
                                    injectInlineWidget(idClient, inlineHtml, widgetTypeIndicator, HISTORY_CARROUSEL_EVENT, config.inlineCustomInjectionSelector, config.inlineCustomSubtitleSelector, upgradeConfig);
                                }, 5000);
                            } else {
                                injectInlineWidget(idClient, inlineHtml, widgetTypeIndicator, HISTORY_CARROUSEL_EVENT, config.inlineCustomInjectionSelector, config.inlineCustomSubtitleSelector, upgradeConfig);
                            }
                        }

                        if (document.readyState === 'complete') {
                            inject();
                        } else {
                             window.addEventListener('load', inject);
                        }
                    }
                    window._wpnOpenModalIfLoadingInProgress(widgetTypeIndicator);
                }
            }
        }
    });
}

//First call, no other function is checking for local history updates
window._wpnWaitingForHistory = false;
//Wait function, used for polling when needed
const wait = function (ms) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
};

//Updates local history if needed and returns the updated products as well
export function updateLocalHistory(idClient) {
    let products = getLocalHistory();
    if (localHistoryNeedsUpdate()) {
        let preparedData = "&idClient=" + idClient + "&products=" + encodeURIComponent(JSON.stringify(products));

        window._wpnWaitingForHistory = true;
        return server.sendToWPN('/product/check-products', preparedData).then(function (parsedProducts) {
            localStorage.setItem(LOCAL_HISTORY_CACHE_KEY, JSON.stringify(parsedProducts));
            markProductHistoryLastCheckTime();
            window._wpnWaitingForHistory = false;
            return parsedProducts;
        });
    }

    return Promise.resolve(products);
}

//Checks if local history needs update
function localHistoryNeedsUpdate() {
    let currentUpdate = parseInt(localStorage.getItem(LOCAL_HISTORY_LAST_UPDATE_KEY));
    if (isNaN(currentUpdate)) {
        currentUpdate = 0;
    }
    const diff = new Date().getTime() - currentUpdate;

    return diff > LOCAL_HISTORY_MILISECONDS_EXPIRE;
}

//Checks if the widget should be displayed based on the upgrade configuration (it should be null or tn admins should be logged in)
function checkWidgetShouldBeDisplayed(upgradeConfig) {
    return (upgradeConfig === null || !upgradeConfig.enabled || document.getElementById('store_bar_iframe')); //Only for admins;
}

//Renders the most viewed widget
export function renderMostViewed(idClient) {
    //Get the visual configuration if not cached already
    getWidgetSettings(idClient, "/product/most-viewed", 'most-viewed').then(function (config) {
        const widgetTypeIndicator = "mv";
        if (config !== undefined && config.template !== undefined && config.products.length > 0) {
            let widgetAvailableForURL = browser.appliesForCurrentURL(config.display, config.pages, window.location.href);
            const inlineAndHomePage = config.showInline && isHomePage(idClient);

            const upgradeConfig = getUpgradeOnSiteConfiguration(config);
            if (checkWidgetShouldBeDisplayed(upgradeConfig) && (widgetAvailableForURL || inlineAndHomePage)) {
                const widgetHtml = addProductsToTemplate(config.template, config.productTemplate, config.products, upgradeConfig);
                addWidget(idClient, config, widgetHtml, widgetTypeIndicator, MOST_VIEWED_EVENT, "open", widgetAvailableForURL);

                if (inlineAndHomePage) {
                    let productsToDisplay = 5;
                    if (browser._wpnGetDeviceType() === browser.DEVICE_PHONE) {
                        productsToDisplay = 3;
                    }
                    let products = config.products.slice(0, productsToDisplay);
                    const inlineHtml = addProductsToTemplate(config.inlineTemplate, config.inlineProductTemplate, products);

                    function inject() {
                        if ('delayInline' in window.wpnObject && window.wpnObject.delayInline) {
                            setTimeout(function() {
                                injectInlineWidget(idClient, inlineHtml, widgetTypeIndicator, MOST_VIEWED_CARROUSEL_EVENT, config.inlineCustomInjectionSelector, config.inlineCustomSubtitleSelector, upgradeConfig);
                            }, 5000);
                        } else {
                            injectInlineWidget(idClient, inlineHtml, widgetTypeIndicator, MOST_VIEWED_CARROUSEL_EVENT, config.inlineCustomInjectionSelector, config.inlineCustomSubtitleSelector, upgradeConfig);
                        }
                    }

                    if (document.readyState === 'complete') {
                        inject();
                    } else {
                         window.addEventListener('load', inject);
                    }
                }
                window._wpnOpenModalIfLoadingInProgress(widgetTypeIndicator);
            }
        }
    });
}

//Checks if the loader modal is waiting for an action to be executed and launches that modal
//We make this function global (window.) so that it can be checked from the product features link to know if the script has already loaded
window._wpnOpenModalIfLoadingInProgress = function (widgetTypeIndicator) {
    if (window._wpnLastFunc === "_wpn_prod_" + widgetTypeIndicator) {
        const widgetLink = document.getElementById("wpn-" + widgetTypeIndicator + "-bubble");
        if (typeof widgetLink !== "undefined" && widgetLink !== null) {
            let evt = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            });
            let elem = document.getElementById('_wpn-modal-backdrop');
            if (typeof elem !== "undefined" && elem !== null) {
                elem.parentNode.removeChild(elem);
            }
            // If cancelled, don't dispatch our event
            widgetLink.dispatchEvent(evt);
        }
    }
};

//Opens an existing modal by making visible the container (modal+backdrop)
function openModal(containerId, idClient) {
    const container = document.getElementById(containerId);
    const backdrop = document.getElementById('_wpn-modal-backdrop');
    if (backdrop) {
        document.body.removeChild(backdrop);
    }

    if (containerId === "wpn-lv-container") {
        //Last visit container might have the link to history. Attach the event listener if exists
        //This event cannot be attached before as the link might not exists in the DOM when another the history widget
        //is called for the first time. To avoid race conditions, we can check before showing the last visit widget if
        //the link exists and THEN attach the click event to it
        const linkFromLastVisitToHistory = document.getElementById('wpn-lv-h-link')
        if (linkFromLastVisitToHistory) {
            linkFromLastVisitToHistory.addEventListener('click', function (e) {
                const experiments = _wpnGetCurrentExperiments();
                const referrer = _wpnRemoveUtmsFromUrl(document.referrer);
                const canonicalUrl = _wpnGetCanonicalUrl();
                const wpnPageType = _wpnGetPageType();

                openModal('wpn-h-container', idClient);
                server.trackEvent(idClient, HISTORY_EVENT, 'open', null, window.location.href, null, wpnPageType, canonicalUrl,
                    referrer, experiments, null);

                browser.sendEventToClientsGA4(_wpnTranslate('History'), null, null, null, "wpn_open");
            });
        }
    } else {
        //If last visit container is open, then close it before showing this other container. The close event should not
        //be triggered for analytics/tracking as that is not an action performed (with intention at least) by the user
        const lastVisitContainer = document.getElementById('wpn-lv-container')
        if (lastVisitContainer !== null) {
            lastVisitContainer.classList.add("wpn-fade");
        }
    }
    if (container) {
        container.classList.remove("wpn-fade");
    }
}


//Adds the widget rendered and binds events based on the client and the different config parameters
function addWidget(idClient, config, widgetHtml, widgetTypeIndicator, eventType, eventWhenOpen = "open", widgetAvailableForURL = true) {
    let body = document.getElementsByTagName('body')[0];
    body.insertAdjacentHTML('beforeend', widgetHtml);

    const experiments = _wpnGetCurrentExperiments();
    const referrer = _wpnRemoveUtmsFromUrl(document.referrer);
    const canonicalUrl = _wpnGetCanonicalUrl();
    const wpnPageType = _wpnGetPageType();
    const containerId = "wpn-" + widgetTypeIndicator + "-container";
    const bubble = document.getElementById("wpn-" + widgetTypeIndicator + "-bubble");
    const simpleLinks = document.getElementsByClassName("wpn-" + widgetTypeIndicator + "-link");
    const container = document.getElementById(containerId);

    let eventLabel = "Most Popular";
    if (widgetTypeIndicator === "lv") {
        eventLabel = "Last Visit";
    } else if (widgetTypeIndicator === "h") {
        eventLabel = "History";
    }
    let translatedLabel = _wpnTranslate(eventLabel)

    //Bind the simple link to open the dialog as well
    for (let i = 0, len = simpleLinks.length | 0; i < len; i = i + 1 | 0) {
        let simpleLink = simpleLinks[i];
        simpleLink.addEventListener('click', function (e) {
            //On click on the button, open the drawer of products and send an event
            openModal(containerId, idClient);

            //Send open event to the tracker
            server.trackEvent(idClient, eventType, eventWhenOpen, null, window.location.href, null, wpnPageType, canonicalUrl,
                referrer, experiments, null);

            browser.sendEventToClientsGA4(translatedLabel, null, null, null, "wpn_open");
        });
    }

    if (bubble !== null) {
        if (config.showIcon && widgetAvailableForURL) {
            bubble.addEventListener('click', function (e) {
                //On click on the button, open the drawer of products and send an event
                openModal(containerId, idClient);

                //Send open event to the tracker
                server.trackEvent(idClient, eventType, eventWhenOpen, null, window.location.href, null, wpnPageType, canonicalUrl,
                  referrer, experiments, null);

                browser.sendEventToClientsGA4(translatedLabel, null, null, null, "wpn_open");
            });

            //Modify hover color to action button
            let originalColor = 'transparent';
            if (bubble.style.backgroundColor) {
                originalColor = ui.rgbToHex(bubble.style.backgroundColor);
                bubble.addEventListener('mouseout', function () {
                    bubble.style.backgroundColor = originalColor;
                });

                //Modify hover color to action button
                bubble.addEventListener('mouseover', function () {
                    bubble.style.backgroundColor = ui.colorLuminance(originalColor, -0.2);
                });
            }

            //The other bubble exists, change current to modify its position
            const LATERAL_POSITION_MOBILE = "10px";
            const LATERAL_POSITION_DESKTOP = "15px";
            let bubbleComputedStyle = getComputedStyle(bubble);

            if (widgetTypeIndicator !== "lv") {
                let bubblesInSamePanel = 0;
                if (!window['wpnObject'].hasOwnProperty('bubbles')) {
                    window['wpnObject'].bubbles = {left: [], right: [], center: []};
                }

                let position = bubbleComputedStyle.right == LATERAL_POSITION_DESKTOP || bubbleComputedStyle.right == LATERAL_POSITION_MOBILE ? 'right' : 'left';

                window['wpnObject'].bubbles[position].push(widgetTypeIndicator);
                if (window['wpnObject'].bubbles[position].length > 1) {
                    bubblesInSamePanel = window['wpnObject'].bubbles[position].length;
                }

                /*
                if (bubbleComputedStyle.right == LATERAL_POSITION_DESKTOP || bubbleComputedStyle.right == LATERAL_POSITION_MOBILE) {
                    window['wpnObject'].bubbles.right.push(widgetTypeIndicator);
                    if (window['wpnObject'].bubbles.right.length > 1) {
                        bubblesInSamePanel = window['wpnObject'].bubbles.right.length;
                    }
                }

                if (bubbleComputedStyle.left == LATERAL_POSITION_DESKTOP || bubbleComputedStyle.left == LATERAL_POSITION_MOBILE) {
                    window['wpnObject'].bubbles.left.push(widgetTypeIndicator);
                    if (window['wpnObject'].bubbles.left.length > 1) {
                        bubblesInSamePanel = window['wpnObject'].bubbles.left.length;
                    }
                }
                */

                if (bubblesInSamePanel > 0) {
                    bubble.style.bottom = (BUBBLE_BOTTOM_START + BUBBLE_HEIGHT_AND_SEPARATION * (bubblesInSamePanel - 1)) + "px";
                }
            }
        } else {
            bubble.style.display = 'none';
        }
    }

    //Define the closing function of the widget
    const closeModal = function (e) {
        if (container !== null) {
            container.classList.add("wpn-fade");
            server.trackEvent(idClient, eventType, "close", null, window.location.href, null, wpnPageType, canonicalUrl,
                referrer, experiments, null);
        }
    };

    //Bind close event if popup exists
    const closeBtn = document.getElementById("wpn-" + widgetTypeIndicator + "-close");
    if (closeBtn !== null) {
        closeBtn.addEventListener("click", closeModal);
    }

    //Close when clicking outside
    if (container !== null) {
        container.addEventListener("click", function (e) {
            if (e.currentTarget === e.target) {
                closeModal(e);
            }
        });
    }

    //Bind click events to product links
    let productLinks = container.getElementsByClassName("wpn-product-link");
    if (productLinks.length > 0) {
        Array.from(productLinks).forEach(function (element) {
                element.addEventListener('click', function () {
                    //If google analytics is set in the client's site, then fire an event when a click is made to the current link

                    let productUrl = element.getAttribute("href"); //Instead of current page, it could be the product's url
                    server.trackEvent(idClient, eventType, "click", null, productUrl, null, wpnPageType, canonicalUrl,
                        referrer, experiments, null);

                    const nameContainer = element.getElementsByClassName("wpn-"+widgetTypeIndicator+"-single-product-title")[0] || null;
                    const name = nameContainer ? nameContainer.innerText : "WPN article";
                    const priceContainer = element.getElementsByClassName("wpn-"+widgetTypeIndicator+"-single-product-price")[0] || null;
                    const price = _parsePriceForGa4(priceContainer ? priceContainer.innerText : "0.0");
                    browser.sendEventToClientsGA4(translatedLabel, name, price, window.wpnObject.currencyCode, "wpn_click");
                });
            }
        );
    }


    //Now that render is finished, check if exists a loading button that was set before the wpn script was loaded
    let spinnerIfWpnNotLoaded = document.getElementById("wpn-spin");
    if (spinnerIfWpnNotLoaded !== null && simpleLink !== null && spinnerIfWpnNotLoaded.dataset.wpnoff == "true") {
        spinnerIfWpnNotLoaded.style.display = "none";
        simpleLink.click();
    }

    ui.checkFlowyOverlappingBubbles();
}

function hideWidgetBubble(type) {
    let bubble = document.getElementById('wpn-' + type + '-bubble')

    if (typeof bubble !== "undefined" && bubble !== null) {
        bubble.style.display = 'none'; //In this way, we can still trigger it from the menu and get all the events (do not remove the bubble)
    }
}

//Creates an object of class Product that has some specific validation rules
class Product {
    constructor(name, price, url, image, seenAt) {
        this.name = name;
        this.price = price;
        this.url = url;
        this.image = image;
        this.seenAt = seenAt;
    }

    //Returns whether the product is valid or not
    isValid() {
        return this.name !== undefined && this.name.length > 0 &&
            this.image !== undefined && this.image.length > 0 &&
            this.price !== undefined && (this.price.length > 0 || this.price > 0.0) &&
            this.url !== undefined && this.url.length > 0
    }
}

// Inline product injection //

//From a given array of rules, checks which selector is found with the greater specificity
function getBestRule(rules) {
    return rules.reduce(function (bestRuleSoFar, currentRule) {
        //Current rule produces no valid selector
        const element = document.querySelector(currentRule.selector);
        if (!element) {
            return bestRuleSoFar;
        }

        //Hidden element; does not work in safari; if the element is invisible; it will not be shown
        //If the element is visible, it is enough to consider it as visible, as parent might be not visible but children can
        //@see https://www.stefanjudis.com/today-i-learned/elements-can-be-visible-even-though-their-parent-has-set-visibility-hidden/
        if(element.checkVisibility === 'function' &&  element.checkVisibility() === false){
            //If element is not visible; then check the parent, as it might be visible. If both are not visible, do not add the element
            if(element.parentElement && element.parentElement.checkVisibility === 'function' && element.parentElement.checkVisibility() === false) {
                return bestRuleSoFar;
            }
        }

        if (bestRuleSoFar === null || bestRuleSoFar.specf < currentRule.specf) {
            return currentRule;
        } else {
            return bestRuleSoFar;
        }

    }, null);
}

//From a given rule, this function extracts the element from the selector. If rule is "multiple", then it can extract
//the last or the first element
function getElementFromRule(rule) {
    if (rule.multiple) {
        let selectorList = document.querySelectorAll(rule.selector);
        if (selectorList.length > 0) {
            if (rule.multiple === "last") {
                return selectorList[selectorList.length - 1];
            } else {
                return selectorList[0];
            }
        }
    } else {
        return document.querySelector(rule.selector);
    }
}

//Returns true if it finds a marker in the markup unique to Mercado Shops stores
function isMercadoShops() {
    const body = document.getElementsByTagName('body')[0];
    return body.dataset.site == "MS";
}

//Detects which kind of ecommerce is by reading the contents of the head tag and trying to parse
//known ecommerce builders. Then it tunes up the ruleset to adapt it to the different platforms
function optimizeRulesBasedOnCurrentPage() {
    //Default Rules for injection
    const rules = [
        {selector: "footer.footer", specf: 0.21, insertion: "beforebegin", multiple: "last"},
        {selector: "footer.page-footer", specf: 0.21, insertion: "beforebegin", multiple: "last"},
        {selector: "footer", specf: 0.2, insertion: "beforebegin", multiple: "last"},
        {selector: "div.footer", specf: 0.11, insertion: "beforebegin", multiple: "last"},
        {selector: "div#footer", specf: 0.11, insertion: "beforebegin", multiple: "last"},
        {selector: "main", specf: 0.1, insertion: "beforeend"},
    ];

    const subtitleRules = [
        {selector: "h2", specf: 0.1,},
        {selector: "h3", specf: 0.09,},
        {selector: "h4", specf: 0.09,},
        {selector: "h5", specf: 0.09,},
        {selector: "h6", specf: 0.09,},
        {selector: "h1", specf: 0.08,},
    ];


    let r = {rules: rules, subtitleRules: subtitleRules};
    let headHtml = document.getElementsByTagName('head')[0].innerHTML;
    if (document.querySelectorAll('[property="tiendanube:price"]').length > 0 || headHtml.search("tiendanube") > 0 || document.querySelectorAll('[property="nuvemshop:price"]').length > 0 || headHtml.search("nuvemshop") > 0) {
        //TN specific code
        r.rules = r.rules.concat([
            {selector: "div#related-products", specf: 0.3, insertion: "afterend"},
        ]);

        r.subtitleRules = r.subtitleRules.concat([
            {selector: "#shopify-section-product-recommendations h2", specf: 0.45,},
        ]);
    } else if (headHtml.search("shopify") > 0) {
        //Shopify
        r.rules = r.rules.concat([
            {selector: "#shopify-section-footer", specf: 0.5, insertion: "beforebegin"},
            {selector: "#shopify-section-promotions", specf: 0.6, insertion: "beforebegin"},
            {selector: "#shopify-section-recomendations", specf: 0.6, insertion: "beforebegin"},
            {selector: ".site-footer-wrapper", specf: 0.6, insertion: "beforebegin"},
        ]);

        r.subtitleRules = r.subtitleRules.concat([
            {selector: "#shopify-section-product-recommendations h2", specf: 0.45,},
            {selector: "#shopify-section-product-recommendations h3", specf: 0.45,},
            {selector: "#shopify-section-recently-viewed-products h3", specf: 0.45,},
            {selector: "div.product-recommendations h2", specf: 0.4,},
            {selector: ".shopify-section h3", specf: 0.4,},
            {selector: ".related-products h3", specf: 0.4,},
        ]);
    } else if (headHtml.search("vtex") > 0) {
        //Vtex
        r.rules = r.rules.concat([
            {selector: "div.vtex-store-footer-2-x-footerLayout", specf: 0.2, insertion: "beforebegin"},
        ])
        r.subtitleRules = r.subtitleRules.concat([
            {selector: ".sugestion h2", specf: 0.2},
        ]);
    } else if (headHtml.search("jumpseller") > 0) {
        //Jumpseller
        r.rules = r.rules.concat([
            {selector: "section#related-products", specf: 0.3, insertion: "afterend"},
        ]);
        r.subtitleRules = r.subtitleRules.concat([
            {selector: "section#related-products h1", specf: 0.2},
        ]);
    } else if (headHtml.search("fenicio") > 0) {
        //Fenicio
        r.subtitleRules = r.subtitleRules.concat([
            {selector: "#blkProductosRelacionados .tit", specf: 0.7},
            {selector: "#blkProductos .tit", specf: 0.7},
            {selector: ".blkProductos .tit", specf: 0.7},
        ]);
    } else if (isMercadoShops()) {
        // Mshops
        r.rules = rules.concat([
            { selector: "#wpn-carrousel-insert", specf: 0.7, insertion: "afterbegin", multiple: "last"},
            { selector: "section.site-shopping-info", specf: 0.33, insertion: "beforebegin", multiple: "last"},
            { selector: "section.buyer-info", specf: 0.35, insertion: "beforebegin", multiple: "last"},
            { selector: ".fashion-nav-footer-contact", specf:0.34, insertion: "beforebegin", multiple: "last"}
        ]);
    } else if(headHtml.search("Loja Integrada") > 0){
        //Loja integrada
        r.rules = rules.concat([
            { selector: "#corpo .conteiner", specf: 0.7, insertion: "beforeend"},
            { selector: "#corpo", specf: 0.6, insertion: "beforeend"},
        ]);
    }

    return r;
}

//Holds a identifier of the last inserted widget so that when inserting another one the same positions are respected deterministically (mv|ri the h)
let inlineInjectedContainer = null;

//Injects the most viewed/history/recommended items widget in the current html document over the footer of the page
function injectInlineWidget(idClient, html, widgetTypeIndicator, eventType, customInjectionSelector = null, customSubtitleSelector = null, upgradeOnsiteConfig = null) {
    const experiments = _wpnGetCurrentExperiments();
    const referrer = _wpnRemoveUtmsFromUrl(document.referrer);
    const canonicalUrl = _wpnGetCanonicalUrl();
    const wpnPageType = _wpnGetPageType();

    let rules = optimizeRulesBasedOnCurrentPage();
    let containerId = "wpn-" + widgetTypeIndicator + "-inline";
    const modalId = "wpn-" + widgetTypeIndicator + "-container"; //open the most viewed/history complete modal

    let injectionRule = customInjectionSelector === null ? getBestRule(rules.rules) : JSON.parse(customInjectionSelector);
    let originalRule = injectionRule;
    if (inlineInjectedContainer !== null && customInjectionSelector === null) {
        //OK, then an inline widget has been injected, do some stuff in order to deterministically place them in the same positions (ie, mv|ri then h).
        if (widgetTypeIndicator === "h") {
            if (inlineInjectedContainer === "wpn-mv-inline") {
                injectionRule = {selector: "#wpn-mv-inline", insertion: "afterend"};
            } else if (inlineInjectedContainer === "wpn-ri-inline") {
                injectionRule = {selector: "#wpn-ri-inline", insertion: "afterend"};
            }
        } else if (inlineInjectedContainer === "wpn-h-inline" && (widgetTypeIndicator === "mv" || widgetTypeIndicator === "ri")) {
            injectionRule = {selector: "#wpn-h-inline", insertion: "beforebegin"};
        }
    }

    if (injectionRule && document.querySelector('#' + containerId) === null) {
        inlineInjectedContainer = containerId;
        let el = getElementFromRule(injectionRule);
        if (!el) {
            el = getElementFromRule(originalRule);
        }

        el.insertAdjacentHTML(injectionRule.insertion, html);

        const upgradeShouldBeDisplayed =  upgradeOnsiteConfig.enabled;
        if(upgradeShouldBeDisplayed && document.getElementById('store_bar_iframe')){ //Only for admins){
            const upgradeHtml = buildUpgradeOnsiteHtml(upgradeOnsiteConfig, true);
            const featureContainer = document.querySelector(".wpn-" +widgetTypeIndicator+ "-inline-list");
            if(featureContainer) {
                featureContainer.style.position = "relative";
                featureContainer.insertAdjacentHTML('beforeend', upgradeHtml);
            }
        }


        /* //TODO: Por ahora no copiamos los estilos de un footer existente
        //Find similar subtitles, and apply the rules of style for those that are alike
        let subtitleRule = customSubtitleSelector === null ?  getBestRule(rules.subtitleRules) : JSON.parse(customSubtitleSelector);
        let subtitleToCopy = getElementFromRule(subtitleRule);
        if (subtitleToCopy) {
            let stylesToCopy = window.getComputedStyle(subtitleToCopy, "");
            let subtitle = document.getElementById("wpn-" + widgetTypeIndicator + "-inline-subtitle");
            if (subtitle && stylesToCopy) {
                subtitle.style.font = stylesToCopy.font;
                subtitle.style.fontSize = stylesToCopy.fontSize;
                subtitle.style.textTransform = stylesToCopy.textTransform;
                subtitle.style.textAlign = "center";
                subtitle.style.color = stylesToCopy.color;
                subtitle.style.display = "block";
                //subtitle.style.textAlign = stylesToCopy.textAlign;

                const backgroundNotSet = "rgba(0, 0, 0, 0)";
                if (subtitle.parentNode) {
                    let parentStyle = window.getComputedStyle(subtitleToCopy.parentNode, "");
                    let bodyStyle = window.getComputedStyle(document.querySelector("body"), "");

                    if (parentStyle.backgroundColor !== backgroundNotSet || bodyStyle.color !== stylesToCopy.color) {
                        subtitle.style.color = bodyStyle.color;
                    }
                }
            }
        }
        */

        let eventLabel = "Most Popular";
        if (widgetTypeIndicator === "lv") {
            eventLabel = "Last Visit";
        } else if (widgetTypeIndicator === "h") {
            eventLabel = "History";
        } else if (widgetTypeIndicator === "ri") {
            eventLabel = "Recommended Items";
        }
        let translatedLabel = _wpnTranslate(eventLabel) + " - " + _wpnTranslate("carrousel");

        //Bind open modal behavior to the view all link
        let links = document.getElementsByClassName("wpn-" + widgetTypeIndicator + "-inline-view-all");
        if (links.length > 0) {
            for (let i = 0; i < links.length; i++) {
                let link = links[i];
                link.addEventListener('click', function (e) {
                    //On click on the button, open the drawer of products and send an event
                    openModal(modalId, idClient);
                    //Send open event to the tracker
                    server.trackEvent(idClient, eventType, "open", null, window.location.href, null, wpnPageType, canonicalUrl,
                        referrer, experiments, null);
                    browser.sendEventToClientsGA4(translatedLabel, null, null, null, "wpn_open");
                });
            }
        }

        //Bind click events to product links
        let container = document.getElementById(containerId);
        let productLinks = container.getElementsByClassName("wpn-inline-product-link");
        if (productLinks.length > 0) {
            Array.from(productLinks).forEach(function (element) {
                    element.addEventListener('click', function () {
                        let productUrl = element.getAttribute("href"); //Instead of current page, it could be the product's url
                        server.trackEvent(idClient, eventType, "click", null, productUrl, null, wpnPageType, canonicalUrl,
                            referrer, experiments, null, "inline");

                        const nameContainer = element.getElementsByClassName("wpn-"+widgetTypeIndicator+"-name")[0] || null;
                        const name = nameContainer ? nameContainer.innerText : "WPN article";
                        const priceContainer = element.getElementsByClassName("wpn-"+widgetTypeIndicator+"-price")[0] || null;
                        const price = _parsePriceForGa4(priceContainer ? priceContainer.innerText : "0.0");
                        browser.sendEventToClientsGA4(translatedLabel, name, price, window.wpnObject.currencyCode, "wpn_click");
                    });
                }
            );
        }

        const windowWidth = window.innerWidth;
        if (windowWidth > RECOMMENDED_WIDTH_CSS_CHANGE) {
            setInlineWidgetHeightForOneLiner(".wpn-" + widgetTypeIndicator + "-inline-list",
                ".wpn-" + widgetTypeIndicator + "-product", 15);
            window.addEventListener('resize', function () {
                setInlineWidgetHeightForOneLiner(".wpn-" + widgetTypeIndicator + "-inline-list",
                    ".wpn-" + widgetTypeIndicator + "-product", 15);
            });
        }

        //Trigger a "view" event when the element is visible in the viewport
        const intersectionObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    server.trackEvent(idClient, eventType, "view", null, window.location.href, null, wpnPageType, canonicalUrl,
                      referrer, experiments, null, "inline");
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.33
        });
        intersectionObserver.observe(container);
    }
}

//Returns a float value from a string representing a price
function _parsePriceForGa4(priceStr)
{
    // Remove currency symbols and surrounding spaces
    const numberString = priceStr.replace(/[^\d.,-]/g, '').trim();

    // Identify the last occurrence of a comma or period
    const lastComma = numberString.lastIndexOf(',');
    const lastPeriod = numberString.lastIndexOf('.');

    // Determine the separator used for decimals, if any
    let decimalSeparator = '';
    if (lastComma > lastPeriod) {
        decimalSeparator = ',';
    } else if (lastPeriod > lastComma) {
        decimalSeparator = '.';
    }

    // Remove thousand separators
    let cleanedNumberString = numberString;
    if (decimalSeparator) {
        const thousandSeparator = decimalSeparator === ',' ? '\\.' : ',';
        cleanedNumberString = cleanedNumberString.replace(new RegExp(thousandSeparator, 'g'), '');
        cleanedNumberString = cleanedNumberString.replace(decimalSeparator, '.');
    }

    return parseFloat(cleanedNumberString);
}

function setInlineWidgetHeightForOneLiner(containerSelector, elementSelector, extraMargin) {
    let element = document.querySelector(elementSelector);
    let container = document.querySelector(containerSelector)

    if (element !== null && container !== null) {
        const elementHeight = element.offsetHeight;
        const totalHeight = elementHeight + extraMargin;

        container.style.overflowY = 'hidden';
        container.style.height = totalHeight + "px";
    }
}

//Renders the recommended items widget
export function renderRecommendedItems(idClient) {
    //Get the visual configuration if not cached already. The products are queried and cached in the getRecommendedProducts function
    getWidgetSettings(idClient, "/recommendation", 'recommended-items').then(function (config) {
        if (config !== undefined && config.template !== undefined && _wpnGetPageType() === _WPN_WEB_PAGE_TYPE_PRODUCT) {

            getRecommendedProducts(idClient, window.location.href).then(function (products) {
                const widgetTypeIndicator = "ri";
                const upgradeConfig = getUpgradeOnSiteConfiguration(config);
                if (checkWidgetShouldBeDisplayed(upgradeConfig) && products && products.length > 0) {
                    const windowWidth = window.innerWidth;
                    if (browser._wpnGetDeviceType() === browser.DEVICE_PHONE) {
                        products = products.slice(0, 4);
                    } else {
                        if (windowWidth > RECOMMENDED_WIDTH_CSS_CHANGE) {
                            const maxProducts = Math.floor(windowWidth / RECOMMENDED_PRODUCT_WIDTH);
                            products = products.slice(0, maxProducts);
                        }
                    }
                    const inlineHtml = addProductsToTemplate(config.template, config.productTemplate, products, upgradeConfig);

                    function inject() {
                        if ('delayInline' in window.wpnObject && window.wpnObject.delayInline) {
                            setTimeout(function() {
                                injectInlineWidget(idClient, inlineHtml, widgetTypeIndicator, RECOMMENDED_ITEMS_EVENT, config.customInjectionSelector, null, upgradeConfig);
                            }, 5000);
                        } else {
                            injectInlineWidget(idClient, inlineHtml, widgetTypeIndicator, RECOMMENDED_ITEMS_EVENT, config.customInjectionSelector, null, upgradeConfig);
                        }
                    }

                    if (document.readyState === 'complete') {
                        inject();
                    } else {
                         window.addEventListener('load', inject);
                    }
                }
            });
        }
    });
}

//Gets the recommended products by querying the specific endpoint and sets up a dedicated local storage cache in order to avoid repeated queries
function getRecommendedProducts(idClient, url) {
    const riCacheKey = "ri-" + window.btoa(url);
    const urlAsParam = "&url=" + encodeURIComponent(url);

    let cached = browser.getFromLocalStorageWithExpiration(riCacheKey);
    if (cached === null || typeof cached === 'undefined' || cached === "undefined") {
        return server.retrieveFromWPN("/recommendation/product", "idClient=" + idClient + urlAsParam).then(function (data) {
            browser.storeInLocalStorageWithExpiration(riCacheKey, JSON.stringify(data.products), RECOMMENDED_PRODUCT_CACHE_DURATION);
            return data.products;
        });
    }
    return Promise.resolve(JSON.parse(cached));
}